import request from '@/util/request'

export function getCellAttendanceList(params) {
  return request({
    url: '/CellAttendance.asmx/CellAttendance_List',
    method: 'GET',
    params,
  })
}

export function CellAttendanceTask(params) {
  return request({
    url: '/CellAttendance.asmx/CellAttendanceTask',
    method: 'GET',
    params,
  })
}
